<template>
  <div class="q-pa-md">
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
        <q-legend
          :label="$t('Notification templates')"
          text-class="text-h6"
        />

        <search
          dense
          autoset
          is-expandable
          @submit="handleSearch"
        />

        <q-space />

        <q-btn
          :color="filters.length > 0 ? 'light-blue-9' : 'dark'"
          text-color="white"
          size="sm"
          class="q-mr-sm"
          :label="filterBtnText"
          no-caps
          unelevated
          @click="toggleFilters"
        />
      </q-card-section>

      <q-card-section class="q-ma-none q-pa-none">
        <filters-collapse
          :is-open="isOpenFilters"
          :options="{
            defaultFilter: filters,
            fields: activatedFields,
            values: {
              'states': states
            }
          }"
          @submit="handleFiltersSubmit"
          @close="toggleFilters"
        />
      </q-card-section>

      <q-card-section class="q-pa-none">
        <q-table
          style="height: calc(100vh - 130px);"
          class="sticky-header-table"
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="templates"
          :columns="columns"
          v-model:pagination="pagination"
          :loading="templatesLoading"
          :filter="filter"
          :rows-per-page-options="[25, 50, 100, 150, 200, 250]"
          selection="multiple"
          v-model:selected="items"
          virtual-scroll
          binary-state-sort
          flat
          @request="onRequest"
        >
          <template v-slot:header-selection="props">
            <q-btn
              color="transparent"
              icon="check"
              size="md"
              :text-color="items.length > 0 ? 'teal' : 'grey'"
              :disabled="max !== null && (items.length >= max || templates.length > max)"
              @click="handleSelectAll(props)"
              flat
            />
          </template>

          <template v-slot:loading>
            <q-inner-loading
              showing
              color="primary"
            />
          </template>

          <template v-slot:body="props">
            <q-tr
              :props="props"
              class="clickable"
            >
              <td class="q-td text-left">
                <q-btn
                  color="transparent"
                  icon="check"
                  size="md"
                  :text-color="items.find(({id}) => id === props.row.id) ? 'teal' : 'grey'"
                  :disabled="max !== null && items.length >= max && !items.find(({id}) => id === props.row.id)"
                  @click="handleCheck(props.row)"
                  flat
                />
              </td>

              <q-td
                key="id"
                :props="props"
                class="text-subtitle1"
              >
                <strong>{{ props.row.id }}</strong>
              </q-td>

              <q-td
                key="name"
                :props="props"
              >
                {{ props.row.name }}
              </q-td>

              <q-td
                key="state"
                :props="props"
              >
                <q-badge
                  :color="stateColors[props.row.state]"
                  text-color="dark"
                  class="q-pa-sm"
                >
                  {{ $t(props.row.state) }}
                </q-badge>
              </q-td>

              <q-td
                key="event"
                :props="props"
              >
                {{ props.row.event }}
              </q-td>

              <q-td
                key="channel"
                :props="props"
              >
                <span v-if="props.row._embedded.channel">
                  {{ props.row._embedded.channel.name }}
                </span>
              </q-td>

              <q-td
                key="owner"
                :props="props"
              >
                <span v-if="props.row._embedded.owner">
                  {{ props.row._embedded.owner.name }}
                </span>
              </q-td>

              <q-td
                key="created"
                :props="props"
              >
                {{ $moment(props.row.created.date).format(appOptions.formats.date) }}
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>

      <q-card-actions v-if="actions" align="center" class="q-pa-xs">
        <q-btn
          color="dark"
          text-color="white"
          size="sm"
          :label="$t('Cancel')"
          no-caps
          unelevated
          @click="handleBack"
        />

        <q-btn
          color="light-blue-9"
          text-color="white"
          size="sm"
          :label="$t('Save')"
          no-caps
          unelevated
          @click="save"
        />
      </q-card-actions>
    </q-card>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapActions } from 'vuex'

// Components
import FiltersCollapse from '../../components/filters/FilterCollapse'
import Search from '../../components/search/Search'

// Utils
import { buildQuery } from '../../utils/query-utils'

export default {
  name: 'NotificationTemplatesTable',
  components: {
    FiltersCollapse,
    Search
  },
  props: {
    max: {
      type: [Number, null],
      default () {
        return null
      }
    },
    actions: {
      type: Boolean,
      default () {
        return false
      }
    },
    selectedItems: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      filters: [
        { type: 'eq', field: 'state', value: 'active' }
      ],
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        },
        {
          label: this.$t('Event'),
          name: 'event',
          align: 'left'
        },
        {
          label: this.$t('Channel'),
          name: 'channel',
          align: 'left'
        },
        {
          label: this.$t('Owner'),
          name: 'owner',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        }
      ],
      stateColors: {
        active: 'success',
        inactive: 'dark'
      },
      isOpenFilters: false,
      activatedFields: [
        'id',
        'name',
        'state',
        'created.to',
        'created.from'
      ],
      states: [
        { id: 'active', title: this.$t('Active') },
        { id: 'inactive', title: this.$t('Inactive') }
      ],
      items: []
    }
  },
  computed: {
    ...mapGetters([
      'templates',
      'totalTemplatesNumber',
      'templatesLoading',
      'appOptions'
    ]),
    filterBtnText () {
      return this.filters && this.filters.length > 0
        ? this.$t('Filtered: ' + this.totalTemplatesNumber)
        : this.$t('Filter')
    }
  },
  mounted () {
    this.items = [...this.selectedItems]
    this.loadDefaultItems()
  },
  methods: {
    ...mapActions([
      'loadTemplates'
    ]),
    handleBack () {
      this.$emit('cancel')
    },
    save () {
      this.$emit('save', this.items)
    },
    handleSelectAll (props) {
      props.selected = !props.selected
    },
    handleCheck (item) {
      let hasItem = false

      this.items = this.items.filter(x => {
        if (x.id === item.id) {
          hasItem = true
          return false
        }

        return true
      })

      if (!hasItem) {
        this.items = [item, ...this.items]
      }
    },
    loadDefaultItems () {
      return this.onRequest({ pagination: {} })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)
      query['order-by'] = [
        {
          type: 'field',
          field: 'created',
          direction: 'desc'
        }
      ]
      query.filter = this.filters

      if (query.search && query.search[query.search.length - 1] !== '*' && query.search[query.search.length - 2] !== ':' && !query.search.includes('%')) {
        query.search += query.search[query.search.length - 1] === ':'
          ? '*'
          : ':*'
      }

      return this.loadTemplates(query)
        .then(({ page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }
        })
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { page: 1, search } })
    },
    handleFiltersSubmit (filter) {
      this.isOpenFilters = false
      this.filters = filter
      return this.onRequest({ pagination: { page: 1 } })
    },
    toggleFilters () {
      this.isOpenFilters = !this.isOpenFilters
    }
  }
}
</script>
