<template>
  <q-dialog v-model="isOpen" class="modal-exl">
    <q-card>
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ $t('Notification logs') }}
        </div>

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="close"
        />
      </q-card-section>

      <q-card-section class="q-ma-none q-pa-none">
        <q-table
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="items"
          :columns="columns"
          v-model:pagination="pagination"
          :loading="isLoading"
          :filter="filter"
          virtual-scroll
          binary-state-sort
          flat
          @request="onRequest"
        >
          <template v-slot:loading>
            <q-inner-loading
              showing
              color="primary"
            />
          </template>

          <template v-slot:body="props">
            <q-tr
              :props="props"
              class="clickable"
              @dblclick="onRowDblClick(props.row)"
            >
              <q-td
                key="id"
                :props="props"
                class="text-subtitle1"
              >
                <strong>{{ props.row.id }}</strong>
              </q-td>

              <q-td
                key="state"
                :props="props"
              >
                <q-badge
                  :color="stateColors[props.row.state]"
                  text-color="dark"
                  class="q-pa-sm"
                >
                  {{ $t(props.row.state) }}
                </q-badge>
              </q-td>

              <q-td
                key="channel"
                :props="props"
              >
                <span v-if="props.row._embedded.channel">
                  {{ props.row._embedded.channel.name }}
                </span>
              </q-td>

              <q-td
                key="task"
                :props="props"
              >
                <span v-if="props.row._embedded.task">
                  {{ props.row._embedded.task.name }}
                </span>
              </q-td>

              <q-td
                key="owner"
                :props="props"
              >
                <span v-if="props.row._embedded.owner">
                  {{ props.row._embedded.owner.name }}
                </span>
              </q-td>

              <q-td
                key="created"
                :props="props"
              >
                {{ $moment(props.row.created.date).format(appOptions.formats.date) }}
              </q-td>

              <q-td
                key="executed"
                :props="props"
              >
                {{ props.row.executed }}
              </q-td>

              <q-td
                key="executionTime"
                :props="props"
              >
                <span v-if="props.row.executionTime">
                  {{ $moment(props.row.executionTime.date).format(appOptions.formats.date) }}
                </span>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// Utils
import { buildQuery } from '../../utils/query-utils'

export default {
  name: 'NotificationEventsModal',
  data () {
    return {
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        },
        {
          label: this.$t('Channel'),
          name: 'channel',
          align: 'left'
        },
        {
          label: this.$t('Task'),
          name: 'task',
          align: 'left'
        },
        {
          label: this.$t('Owner'),
          name: 'owner',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        },
        {
          label: this.$t('Executed'),
          name: 'executed',
          align: 'left'
        },
        {
          label: this.$t('Execution Time'),
          name: 'executionTime',
          align: 'left'
        }
      ],
      stateColors: {
        pending: 'grey-4 text-dark',
        prepared: 'success',
        sent: 'info',
        delivered: 'info',
        error: 'danger',
        deleted: 'dark'
      },
      isLoading: false,
      items: [],
      filters: [],
      isOpen: false
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ])
  },
  methods: {
    open (filter) {
      this.filters = filter
      this.isOpen = true
      this.onRequest({ pagination: { page: 1, per_page: 25 } })
    },
    close () {
      this.isOpen = false
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)
      query.filter = this.filters

      this.isLoading = true
      return this.$service.notificationEvent.getAll(query)
        .then(({ page, totalItems, items }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }

          this.items = items
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
