<template>
  <div>
    <div class="q-pa-md">
      <div class="row border-bottom">
        <div class="col-12 col-md-6 q-pa-xs">
          <form-builder :schema="schema" />
        </div>

        <q-space />

        <div v-if="notificationTask && notificationTask.id" class="col-12 col-md-6 q-pa-xs row items-center justify-end">
          <span class="q-mr-xl text-capitalize">
            {{ $t(notificationTask.state) }}
          </span>

          <span
            class="q-mx-xl clickable"
            @click="handleLog"
          >
            {{ $t('Log') }}
          </span>
        </div>
      </div>

      <div class="q-py-md">
        <div class="text-center q-pb-xs">
          <label class="text-h6 text-weight-bold">
            {{ $t('Use the template') }}
          </label>
        </div>

        <div class="row">
          <div class="col q-px-xs">
            <q-field square filled stack-label>
              <template v-slot:control>
                <div class="self-center full-width no-outline" tabindex="0">
                  <q-badge
                    v-for="item in templates"
                    :key="item.id"
                    color="light-blue-6"
                    class="q-mx-xs q-py-xs q-px-sm text-capitalize clickable"
                  >
                    <span @click="setTemplate(item)">
                      {{ item.name }}
                    </span>

                    <div
                      class="text-dark q-ml-sm"
                      @click="removeTemplate(item)"
                    >
                      X
                    </div>
                  </q-badge>
                </div>
              </template>
            </q-field>
          </div>

          <q-btn
            color="light-blue-9"
            :label="$t('Add')"
            icon="add"
            :disabled="templates.length > 0"
            @click="toggleTemplateTable"
          />
        </div>

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          class="text-caption"
          :disabled="templates.length > 0"
          flat
          no-caps
          @click="setTemplate(null)"
        >
          + {{ $t('Create new') }}
        </q-btn>
      </div>

      <q-slide-transition>
        <notification-template-panel
          v-if="isOpenTemplate"
          :model="template"
          :keywords="keywords[(notificationTask || {}).event] || keywords.default"
          @save="handleTemplate"
          @cancel="toggleTemplate"
        />
      </q-slide-transition>

      <q-slide-transition>
        <notification-templates-table
          v-if="isOpenTemplateTable"
          :selected-items="templates"
          :max="1"
          actions
          @save="handleTemplateTable"
          @cancel="toggleTemplateTable"
        />
      </q-slide-transition>

      <div class="q-py-md">
        <div class="text-center q-pb-xs">
          <label class="text-h6 text-weight-bold">
            {{ $t('For the channel') }}
          </label>
        </div>

        <div class="row">
          <div class="col q-px-xs">
             <q-field square filled stack-label>
              <template v-slot:control>
                <div class="self-center full-width no-outline" tabindex="0">
                  <q-badge
                    v-for="item in channels"
                    :key="item.id"
                    color="light-blue-6"
                    class="q-mx-xs q-py-xs q-px-sm text-capitalize"
                  >
                    <span @click="setChannel(item)">
                      {{ item.name }}
                    </span>

                    <div
                      class="text-dark q-ml-sm"
                      @click="removeChannel(item)"
                    >
                      X
                    </div>
                  </q-badge>
                </div>
              </template>
            </q-field>
          </div>

          <q-btn
            color="light-blue-9"
            :label="$t('Add')"
            icon="add"
            :disabled="channels.length > 0"
            @click="toggleChannelTable"
          />
        </div>

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          class="text-caption"
          flat
          no-caps
          :disabled="channels.length > 0"
          @click="setChannel(null)"
        >
          + {{ $t('Create new') }}
        </q-btn>
      </div>

      <q-slide-transition>
        <notification-channel-panel
          v-if="isOpenChannel"
          :model="channel"
          @save="handleChannel"
          @cancel="toggleChannel"
        />
      </q-slide-transition>

      <q-slide-transition>
        <notification-channels-table
          v-if="isOpenChannelTable"
          :selected-items="channels"
          :max="1"
          actions
          @save="handleChannelTable"
          @cancel="toggleChannelTable"
        />
      </q-slide-transition>

      <div class="q-py-md">
        <div class="text-center q-pb-xs">
          <label class="text-h6 text-weight-bold">
            {{ $t('If') }}
          </label>
        </div>

        <div class="row">
          <div class="col q-px-xs">
            <q-field square filled stack-label>
              <template v-slot:control>
                <div class="self-center full-width no-outline" tabindex="0">
                  <q-badge
                    v-for="(item, i) in ((notificationTask || {}).conditions || [])"
                    :key="item.id"
                    color="light-blue-6"
                    class="q-mx-xs q-py-xs q-px-sm text-capitalize"
                  >
                    {{ getConditionName(item) }}

                    <div
                      class="text-dark q-ml-sm"
                      @click="removeCondition(i)"
                    >
                      X
                    </div>
                  </q-badge>
                </div>
              </template>
            </q-field>
          </div>

          <q-btn
            color="light-blue-9"
            :label="$t('Add')"
            icon="add"
            @click="toggleFilter"
          />
        </div>
      </div>

      <filter-collapse
        :is-open="isOpenFilter"
        :options="{
          defaultFilter: (notificationTask || []).conditions || [],
          fields: fields[(notificationTask || {}).event] || fields.default,
          values: values[(notificationTask || {}).event] || values.default,
          style: {
            noGroups: true
          }
        }"
        @submit="handleFilterSubmit"
        @close="toggleFilter"
      />
    </div>

    <sticky-bottom-header
      :is-loading="isSaveLoading"
      :is-active="hasChange"
      @back="handleDiscard"
      @save="save"
    />

    <notification-events-modal ref="eventsModal" />
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Utils
import _ from 'lodash'

// Components
import FilterCollapse from '../../components/filters/FilterCollapse.vue'
import NotificationChannelPanel from '../../components/panels/NotificationChannelPanel.vue'
import NotificationTemplatePanel from '../../components/panels/NotificationTemplatePanel.vue'
import NotificationChannelsTable from '../../components/tables/NotificationChannelsTable.vue'
import NotificationEventsModal from '../../components/modals/NotificationEventsModal.vue'
import NotificationTemplatesTable from '../../components/tables/NotificationTemplatesTable.vue'

// Configs
import OrderMatrix from '../../config/OrderMatrix'
import DeliveryRequestMatrix from '../../config/DeliveryRequestMatrix'

export default {
  name: 'Task',
  components: {
    FilterCollapse,
    NotificationChannelPanel,
    NotificationTemplatePanel,
    NotificationChannelsTable,
    NotificationEventsModal,
    NotificationTemplatesTable
  },
  data () {
    return {
      isOpenTemplateTable: false,
      isOpenChannelTable: false,
      isOpenFilter: false,
      isOpenChannel: false,
      isOpenTemplate: false,
      template: null,
      channel: false,
      isSaveLoading: false,
      hasChange: false,
      templates: [],
      originalTemplates: [],
      channels: [],
      originalChannels: [],
      values: {
        default: {},
        'storage.movements.acceptance.saved:acceptance': {
          types: [
            { id: 'products', title: 'Products' },
            { id: 'items', title: 'Items' },
            { id: 'palletized', title: 'Palletized' }
          ],
          states: [
            { id: 'checking', title: 'Checking' },
            { id: 'new', title: 'New' },
            { id: 'confirmed', title: 'Confirmed' }
          ]
        },
        'products.orders.saved:order': {
          states: OrderMatrix
        },
        'delivery.services.request.saved:deliveryRequest': {
          states: DeliveryRequestMatrix
        },
        'products.orders.reserve.saved:reserve': {
          states: [
            { id: 'new', title: 'New' },
            { id: 'confirmed', title: 'Confirmed' },
            { id: 'queued', title: 'Queued' },
            { id: 'processing', title: 'Processing' },
            { id: 'complete', title: 'Complete' },
            { id: 'cancelled', title: 'Cancelled' },
            { id: 'sorting', title: 'Sorting' },
            { id: 'distribution', title: 'Distribution' },
            { id: 'packaging', title: 'Packaging' },
          ]
        },
        'storage.item.save.post:item': {},
      },
      fields: {
        'products.orders.reserve.saved:reserve': ['warehouse', 'shop', 'state'],
        'storage.item.save.post:item': ['shop', 'warehouse'],
        'products.orders.saved:order': ['id', 'extId', 'clientId', 'profile', 'phone', 'shipmentDate.from', 'shipmentDate.to', 'created.from', 'created.to', 'deliveryService', 'paymentState', 'state', 'shop', 'source', 'deliveryRequest'],
        'delivery.services.request.saved:deliveryRequest': ['id', 'extId', 'clientExtId', 'trackingNumber', 'shippedByDocument', 'createdByDocument', 'recipient', 'recipientPhone', 'weight.from', 'weight.to', 'retailPrice.from', 'retailPrice.to', 'estimatedCost.from', 'estimatedCost.to', 'payment.from', 'payment.to', 'shipmentDate.from', 'shipmentDate.to', 'deliveryDate.from', 'deliveryDate.to', 'pickUpDate.from', 'pickUpDate.to', 'created.from', 'created.to', 'deliveryService', 'rate', 'servicePoint', 'integration', 'paymentState', 'state', 'sender'],
        'storage.movements.acceptance.saved:acceptance': ['id', 'extId', 'state', 'type', 'warehouse'],
        default: []
      },
      events: [
        { id: 'products.orders.saved:order', name: 'Order saved' },
        { id: 'delivery.services.request.saved:deliveryRequest', name: 'Shipment saved' }
      ],
      keywords: {
        default: [],
        'products.orders.saved:order': [
          { id: 'id', name: 'ID' },
          { id: 'extId', name: 'ExtId' },
          { id: 'deliveryRequest.trackingNumber', name: 'Tracking number' },
          { id: 'deliveryRequest.deliveryService.name', name: 'Delivery Carrier' }
        ],
        'delivery.services.request.saved:deliveryRequest': [
          { id: 'id', name: 'ID' },
          { id: 'extId', name: 'ExtId' },
          { id: 'trackingNumber', name: 'Tracking number' },
          { id: 'deliveryService.name', name: 'Delivery Carrier' },
          { id: 'servicePoint.rawAddress', name: 'Pick up point address' },
          { id: 'recipientAddress.postcode', name: 'Postcode' },
          { id: 'payment', name: 'Payment' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters([
      'notificationTask'
    ]),
    isActive () {
      return this.notificationTask && this.notificationTask.state === 'active'
    },
    schema () {
      return {
        isLoading: !this.notificationTask,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Name'),
                field: 'name',
                value: this.notificationTask && this.notificationTask.name,
                required: true,
                wrapperStyleClasses: 'col-6 q-pa-xs',
                onChange: name => {
                  this.updateNotificationTask({ name })
                  this.hasChange = true
                }
              },
              {
                type: 'select',
                label: this.$t('Event'),
                field: 'event',
                value: this.notificationTask && this.notificationTask.event,
                wrapperStyleClasses: 'col-6 q-pa-xs',
                options: this.events,
                required: true,
                customLabel: (row) => {
                  if (row) {
                    if (typeof row === 'object') {
                      return this.$t(row.name)
                    }

                    const item = this.events.find(x => x.id === row)

                    if (item) {
                      return this.$t(item.name)
                    }
                  }

                  return row
                },
                onChange: (event) => {
                  this.updateNotificationTask({ event: event && event.id })
                  this.hasChange = true
                }
              },
            ]
          }
        ]
      }
    },
    page () {
      return {
        id: this.notificationTask && this.notificationTask.id,
        name: this.notificationTask && this.notificationTask.id
          ? this.$t('Task')
          : this.$t('New task')
      }
    },
    headerModel () {
      if (!this.notificationTask) {
        return []
      }

      return [
        {
          section: 'BackAction',
          className: 'q-pr-sm hide-on-mobile',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              label: this.$t('Back'),
              onClick: this.handleBack
            }
          ]
        },
        {
          section: 'Title',
          className: 'col q-px-xs text-white mobile-title',
          options: [
            {
              id: 'title',
              type: 'text',
              value: this.notificationTask && this.notificationTask.id
                ? this.$t('Task ID: ') + this.notificationTask.id
                : this.$t('New Task')
            }
          ]
        }
      ]
    }
  },
  mounted () {
    if (this.$route.params.id) {
      if (!this.notificationTask) {
        return this.loadNotificationTask(this.$route.params.id)
          .then(() => {
            const query = {
              page: 1,
              per_page: 25
            }

            this.loadChannels(query)
            this.loadTemplates(query)
          })
      }

      const query = {
        page: 1,
        per_page: 25
      }

      this.loadChannels(query)
      this.loadTemplates(query)
      return
    }

    if (!this.$route.params.id) {
      this.setNewNotificationTask()

      const owner = JSON.parse(localStorage.getItem('userData'))
      this.updateNotificationTask({
        _embedded: {
          ...this.notificationTask._embedded,
          owner
        }
      })
    }
  },
  unmounted () {
    this.setNotificationTask(null)
  },
  methods: {
    ...mapActions([
      'loadNotificationTask',
      'saveNotificationTask'
    ]),
    ...mapMutations([
      'setNewNotificationTask',
      'setNotificationTask',
      'updateNotificationTask',
      'updateNotificationTaskEmbedded'
    ]),
    handleLog () {
      this.$refs.eventsModal.open([{ type: 'eq', field: 'task', value: this.$route.params.id }])
    },
    removeCondition (index) {
      this.updateNotificationTask({
        conditions: this.notificationTask.conditions.filter((x, i) => i != index)
      })

      this.hasChange = true
      return this.notificationTask.conditions
    },
    removeChannel (channel) {
      this.channels = this.channels.filter(x => x.id != channel.id)
      this.hasChange = true

      return this.channels
    },
    removeTemplate (template) {
      this.templates = this.templates.filter(x => x.id != template.id)
      this.hasChange = true

      return this.templates
    },
    handleTemplateTable (items) {
      this.templates = items
      this.hasChange = true
      this.toggleTemplateTable()
      return this.templates
    },
    handleChannelTable (items) {
      this.channels = items
      this.hasChange = true
      this.toggleChannelTable()
      return this.channels
    },
    handleChannel (channel) {
      this.toggleChannel()
      this.upsertChannel(channel)
      this.hasChange = true
    },
    handleTemplate (template) {
      this.toggleTemplate()
      this.upsertTemplate(template)
      this.hasChange = true
    },
    handleFilterSubmit (conditions) {
      this.updateNotificationTask({ conditions })
      this.toggleFilter()
      this.hasChange = true
    },
    toggleTemplateTable () {
      this.isOpenTemplateTable = !this.isOpenTemplateTable
    },
    toggleChannelTable () {
      this.isOpenChannelTable = !this.isOpenChannelTable
    },
    toggleChannel () {
      this.isOpenChannel = !this.isOpenChannel
    },
    toggleTemplate () {
      this.isOpenTemplate = !this.isOpenTemplate
    },
    toggleFilter () {
      this.isOpenFilter = !this.isOpenFilter
    },
    upsertTemplate (template) {
      let isFounded = false

      this.templates = this.templates.map(x => {
        if (x.id == template.id) {
          isFounded = true
          return template
        }

        return x
      })

      if (!isFounded) {
        this.templates.push(template)
      }

      return this.templates
    },
    upsertChannel (channel) {
      let isFounded = false

      this.channels = this.channels.map(x => {
        if (x.id == channel.id) {
          isFounded = true
          return channel
        }

        return x
      })

      if (!isFounded) {
        this.channels.push(channel)
      }

      return this.channels
    },
    setChannel (channel) {
      this.channel = channel
      this.isOpenChannel = true
    },
    setTemplate (template) {
      this.template = template
      this.isOpenTemplate = true
    },
    getConditionName (condition) {
      const value = condition.values
        ? condition.values.join(', ')
        : condition.value

      return `${condition.field} - ${value}`
    },
    loadChannels (pagination) {
      this.channels = []

      if (this.notificationTask._embedded.channel) {
        this.channels.push(this.notificationTask._embedded.channel)
      }

      this.originalChannels = _.cloneDeep(this.channels)
      // const query = {
      //   page: 1,
      //   per_page: 25,
      //   filter: [
      //     { field: 'task', type: 'eq', value: this.$route.params.id },
      //     { field: 'state', type: 'eq', value: 'active' }
      //   ],
      //   ...pagination
      // }

      // return this.$service.notificationChannel.getAll(query)
      //   .then(data => {
      //     if (query.page <= 1) {
      //       this.channels = data.items
      //       this.originalChannels = _.cloneDeep(data.items)
      //     } else {
      //       this.channels = [...this.channels, ...data.items]
      //       this.originalChannels = [...this.originalChannels, ..._.cloneDeep(data.items)]
      //     }

      //     return data
      //   })
    },
    loadTemplates (pagination) {
      if (!this.notificationTask.template) {
        this.templates = []
        this.originalTemplates = []
        return
      }

      return this.$service.notificationTemplate.get(this.notificationTask.template)
        .then(template => {
          this.templates = [template]
          this.originalTemplates = _.cloneDeep(this.templates)
        })
      // const query = {
      //   page: 1,
      //   per_page: 25,
      //   filter: [
      //     { field: 'id', type: 'eq', value: this.notificationTask.template },
      //     { field: 'state', type: 'eq', value: 'active' }
      //   ],
      //   ...pagination
      // }

      // return this.$service.notificationTemplate.getAll(query)
        // .then(data => {
        //   if (query.page <= 1) {
        //     this.templates = data.items
        //     this.originalTemplates = _.cloneDeep(data.items)
        //   } else {
        //     this.templates = [...this.templates, ...data.items]
        //     this.originalTemplates = [...this.originalTemplates, ..._.cloneDeep(data.items)]
        //   }

        //   return data
        // })
    },
    save () {
      this.isSaveLoading = true

      // Sideeffect
      // In future we can have more than one template and channel so this will be changed
      this.updateNotificationTask({ template: (this.templates[0] || {}).id })
      this.updateNotificationTaskEmbedded({ channel: this.channels[0] })

      this.saveNotificationTask()
        .then(() => {
          this.hasChange = false
          if (!this.notificationTask.id) {
            this.handleBack()
          }
        })
        .finally(() => {
          this.isSaveLoading = false
        })
    },
    handleDiscard () {
      this.$router.go()
    },
    handleBack () {
      this.$router.back()
    }
  }
}
</script>
