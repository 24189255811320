// const fields = [
//   'id',
//   'extId',
//   'clientExtId',
//   'created.date',
//   'sendDate.date',
//   'updated.date',
//   'deliveryDate.date',
//   'deliveryTimeEnd',
//   'deliveryTimeStart',
//   'trackingNumber',
//   'shippedByDocument',
//   'eav',
//   'estimatedCost',
//   'payment',
//   'retailPrice',
//   'paymentState',
//   'places',
//   'recipientComment',
//   'state',
//   'deliveryService',
//   'order',
//   'rate',
//   'recipient',
//   'recipient.name',
//   'recipient.surname',
//   'recipient.email',
//   'recipientAddress',
//   'recipientAddress.postcode',
//   'recipientAddress.locality',
//   'recipientAddress.street',
//   'recipientAddress.house',
//   'recipientAddress.quarter',
//   'recipientAddress.block',
//   'recipientAddress.entrance',
//   'recipientAddress.floor',
//   'recipientAddress.apartment',
//   'recipientAddress.notFormal',
//   'recipientLocality',
//   'recipientPhone',
//   'recipientPhone.phone',
//   'sender',
//   'senderName',
//   'servicePoint'
// ]

// export const s = [
//   {
//     "states": [
//       "pending_queued",
//       "cancelled",
//       "delete",
//       "sent",
//       "processing",
//       "waiting",
//       "denied",
//       "disposed",
//       "lost",
//       "returning",
//       "returning_partly",
//       "return_waiting",
//       "returned",
//       "returned_partly",
//       "complete",
//       "complete_partly",
//       "complete_returned"
//     ],
//     "properties": [
//       "id",
//       "extId",
//       "clientExtId",
//       "created.date",
//       "sendDate.date",
//       "updated.date",
//       "deliveryDate.date",
//       "deliveryTimeEnd",
//       "deliveryTimeStart",
//       "trackingNumber",
//       "shippedByDocument",
//       "eav",
//       "estimatedCost",
//       "payment",
//       "retailPrice",
//       "paymentState",
//       "places",
//       "recipientComment",
//       "state",
//       "deliveryService",
//       "order",
//       "rate",
//       "recipient",
//       "recipient.name",
//       "recipient.surname",
//       "recipient.email",
//       "recipientAddress",
//       "recipientAddress.postcode",
//       "recipientAddress.locality",
//       "recipientAddress.street",
//       "recipientAddress.house",
//       "recipientAddress.quarter",
//       "recipientAddress.block",
//       "recipientAddress.entrance",
//       "recipientAddress.floor",
//       "recipientAddress.apartment",
//       "recipientAddress.notFormal",
//       "recipientLocality",
//       "recipientPhone",
//       "recipientPhone.phone",
//       "sender",
//       "senderName",
//       "servicePoint"
//     ],
//     "permission": ["read"]
//   },
//   {
//     "states": [
//       "pending",
//       "pending_error",
//       "error",
//       "pre_processing"
//     ],
//     "properties": [
//       "id",
//       "extId",
//       "clientExtId",
//       "created.date",
//       "sendDate.date",
//       "updated.date",
//       "deliveryDate.date",
//       "deliveryTimeEnd",
//       "deliveryTimeStart",
//       "trackingNumber",
//       "shippedByDocument",
//       "eav",
//       "estimatedCost",
//       "payment",
//       "retailPrice",
//       "paymentState",
//       "places",
//       "recipientComment",
//       "state",
//       "deliveryService",
//       "order",
//       "rate",
//       "recipient",
//       "recipient.name",
//       "recipient.surname",
//       "recipient.email",
//       "recipientAddress",
//       "recipientAddress.postcode",
//       "recipientAddress.locality",
//       "recipientAddress.street",
//       "recipientAddress.house",
//       "recipientAddress.quarter",
//       "recipientAddress.block",
//       "recipientAddress.entrance",
//       "recipientAddress.floor",
//       "recipientAddress.apartment",
//       "recipientAddress.notFormal",
//       "recipientLocality",
//       "recipientPhone",
//       "recipientPhone.phone",
//       "sender",
//       "senderName",
//       "servicePoint"
//     ],
//     "permission": ["read", "write"]
//   },
//   {
//     "states": [
//       "pending_queued",
//       "cancelled",
//       "pending",
//       "pending_error",
//       "error",
//       "delete",
//       "pre_processing",
//       "sent",
//       "processing",
//       "waiting",
//       "denied",
//       "disposed",
//       "lost",
//       "returning",
//       "returning_partly",
//       "return_waiting",
//       "cancelled",
//       "returned",
//       "returned_partly",
//       "complete",
//       "complete_partly",
//       "complete_returned"
//     ],
//     "properties": [
//       "estimatedCost",
//       "payment",
//       "retailPrice",
//       "deliveryService",
//       "rate",
//       "recipient",
//       "recipient.name",
//       "recipientAddress",
//       "recipientAddress.postcode",
//       "recipientAddress.locality",
//       "recipientAddress.street",
//       "recipientAddress.house",
//       "recipientLocality",
//       "recipientPhone",
//       "recipientPhone.phone",
//       "sender",
//       "senderName",
//       "servicePoint"
//     ],
//     "options": {
//       "required": true
//     }
//   },
//   {
//     "states": ["cancelled"],
//     "properties": ["state"],
//     "options": {
//       "required": true,
//       "values": ["pending_queued"]
//     }
//   },
//   {
//     "states": ["pending"],
//     "properties": ["state"],
//     "options": {
//       "required": true,
//       "values": ["pending_queued", "pre_processing", "sent", "cancelled", "deleted"]
//     }
//   },
//   {
//     "states": ["pending_error"],
//     "properties": ["state"],
//     "options": {
//       "required": true,
//       "values": ["pending_queued", "cancelled"]
//     }
//   },
//   {
//     "states": ["error", "pre_processing"],
//     "properties": ["state"],
//     "options": {
//       "required": true,
//       "values": ["pending_queued", "pre_processing", "sent", "cancelled"]
//     }
//   },
//   {
//     "states": ["sent"],
//     "properties": ["state"],
//     "options": {
//       "required": true,
//       "values": ["pending_queued", "pre_processing", "cancelled"]
//     }
//   }
// ]
export default [
  {
    id: 1,
    group: 'Default',
    color: 'grey-1',
    buttons: [
      {
        id: 'pending_queued',
        title: 'Pending Queued',
        color: 'grey-4',
        editableField: false,
        transitions: []
      },
      {
        id: 'cancelled',
        title: 'Cancelled',
        color: 'grey-5',
        editableField: false,
        transitions: ['pending_queued']
      }
    ]
  },
  {
    id: 2,
    group: 'Warning',
    color: 'yellow',
    buttons: [
      {
        id: 'pending',
        title: 'Pending',
        color: 'yellow-4',
        editableField: true,
        transitions: ['pending_queued', 'pre_processing', 'sent', 'cancelled', 'deleted']
      },
      {
        id: 'pending_error',
        title: 'Pending error',
        color: 'yellow-5',
        editableField: true,
        transitions: ['pending_queued', 'cancelled']
      }
    ]
  },
  {
    id: 3,
    group: 'Danger',
    color: 'danger',
    buttons: [
      {
        id: 'error',
        title: 'Error',
        editableField: true,
        transitions: ['pending_queued', 'pre_processing', 'sent', 'cancelled']
      },
      {
        id: 'delete',
        title: 'Delete',
        editableField: false,
        transitions: []
      }
    ]
  },
  {
    id: 4,
    group: 'Prepearing',
    color: 'success',
    buttons: [
      {
        id: 'pre_processing',
        title: 'Preprocessing',
        color: 'cyan-14',
        editableField: true,
        transitions: ['pending_queued', 'pre_processing', 'sent', 'cancelled']
      }
    ]
  },
  {
    id: 5,
    group: 'Delivery',
    color: 'info',
    buttons: [
      {
        id: 'sent',
        title: 'Sent',
        color: 'light-blue-2',
        editableField: false,
        transitions: ['pending_queued', 'pre_processing', 'cancelled']
      },
      {
        id: 'processing',
        title: 'Processing',
        color: 'light-blue-3',
        editableField: false,
        transitions: []
      },
      {
        id: 'waiting',
        title: 'Waiting',
        color: 'light-blue-4',
        editableField: false,
        transitions: []
      },
      {
        id: 'denied',
        title: 'Denied',
        color: 'light-blue-5',
        editableField: false,
        transitions: []
      },
      {
        id: 'disposed',
        title: 'Disposed',
        color: 'light-blue-6',
        editableField: false,
        transitions: []
      },
      {
        id: 'lost',
        title: 'Lost',
        color: 'light-blue-7',
        editableField: false,
        transitions: []
      }
    ]
  },
  {
    id: 6,
    group: 'Return',
    color: 'purple',
    buttons: [
      {
        id: 'returning',
        title: 'Returning',
        color: 'deep-purple-3',
        editableField: false,
        transitions: []
      },
      {
        id: 'returning_partly',
        title: 'Returning partly',
        color: 'deep-purple-4',
        editableField: false,
        transitions: []
      },
      {
        id: 'return_waiting',
        color: 'deep-purple-5',
        title: 'Return waiting',
        editableField: false,
        transitions: []
      }
    ]
  },
  {
    id: 7,
    group: 'Complete',
    color: 'inverse',
    buttons: [
      {
        id: 'cancelled',
        title: 'Cancelled',
        color: 'grey-5',
        editableField: false,
        transitions: []
      },
      {
        id: 'returned',
        title: 'Returned',
        color: 'grey-6',
        editableField: false,
        transitions: []
      },
      {
        id: 'returned_partly',
        title: 'Returned partly',
        color: 'grey-7',
        editableField: false,
        transitions: []
      },
      {
        id: 'complete',
        title: 'Complete',
        color: 'grey-8',
        editableField: false,
        transitions: []
      },
      {
        id: 'complete_partly',
        title: 'Complete partly',
        editableField: false,
        color: 'grey-9',
        transitions: []
      },
      {
        id: 'complete_returned',
        title: 'Complete returned',
        editableField: false,
        color: 'grey-10',
        transitions: []
      }
    ]
  }
]
